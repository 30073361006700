import { useQuery } from "@tanstack/vue-query"

import { CommonService } from "../services/common.service"
import { UseQueryWrapperProps } from "./useFinqProducts"

export function useGeneralParameters({
  enabled = true,
  onSuccess = () => null,
  onError = () => null,
}: UseQueryWrapperProps = {}) {
  const dayjs = useDayjs()

  const query = useQuery({
    queryKey: ["finq-general-parameters"],
    queryFn: CommonService.getGeneralParameters,
    staleTime: 5 * 60 * 1000,
    enabled,
  })

  if (onSuccess) watchEffect(() => onSuccess(query.isSuccess.value))
  if (onError) watchEffect(() => onError(query.isError.value))

  const getFixedFundBatchRunDate = computed((): number => {
    if (!query.data.value) return 0
    const date = query.data.value.fundBatchRunDate

    return dayjs.unix(date).subtract(1, "day").unix()
  })

  return { ...query, getFixedFundBatchRunDate }
}
